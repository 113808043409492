import styled from '@emotion/styled'
import { ZoomIn } from 'app/components/Common/Animation/ZoomIn'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import React, { memo } from 'react'

export interface Props {
  title?: string
}

export const SlidingText = memo(function SlidingText({ title }: Props) {
  if (!title) {
    return null
  }

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Wrapper>
          <Media greaterThanOrEqual="desktopSmall">
            <ZoomIn>
              <Title>{title}</Title>
            </ZoomIn>
          </Media>

          <Media lessThan="desktopSmall">
            <ZoomIn>
              <SimpleText>{title}</SimpleText>
            </ZoomIn>
          </Media>
        </Wrapper>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 6.25rem 0;
`

const Wrapper = styled.div`
  max-width: 50.625rem;
  background: url('illustration-23.svg') no-repeat center;
  background-size: contain;
  margin: auto;
  padding: 13.4375rem 0;
  text-align: center;

  @media (max-width: 1199px) {
    padding: 5.625rem 3.75rem;
  }

  @media (max-width: 374px) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.625rem;
  font-weight: 600;
  line-height: 3.75rem;
  text-transform: uppercase;

  > div {
    margin: 0 5.417vw;
  }
`

const SimpleText = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 1.875rem;
  text-align: center;
  text-transform: uppercase;
  word-wrap: break-word;
`
