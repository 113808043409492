import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export const ZoomIn = memo(function ZoomIn({ children }: any) {
  const [ref, isVisible] = useInView({ threshold: 0.35 })
  const variants = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        damping: 25,
        stiffness: 120,
        type: 'spring',
      },
    },
    hidden: {
      opacity: 0,
      scale: 0,
    },
  }

  return (
    <motion.div
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      variants={variants}
    >
      {children}
    </motion.div>
  )
})
