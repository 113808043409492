import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Marks } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo } from 'react'

export interface Props {
  reviews?: string[]
}

export const Reviews = memo(function Reviews({ reviews }: Props) {
  if (!reviews) {
    return null
  }

  const [sliderRef, galleryRef] = useKeenSlider({
    drag: true,
    loop: reviews.length > 1 ? true : false,
  })

  return (
    <Container row space="between" wrap>
      <LeftSide>
        <Slider className="grab-cursor" ref={sliderRef}>
          {reviews.map((item: any, index) => (
            <Slide key={index} className="keen-slider__slide">
              {item.description ? (
                <Description
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              ) : null}

              {item.user ? <User>{item.user}</User> : null}
            </Slide>
          ))}
        </Slider>

        {reviews.length > 1 ? (
          <Arrows row>
            <Arrow
              onClick={(e) => e.stopPropagation() || galleryRef.current?.prev()}
            />
            <Arrow
              direction="R"
              onClick={(e) => e.stopPropagation() || galleryRef.current?.next()}
            />
          </Arrows>
        ) : null}
      </LeftSide>

      <RightSide>
        <Marks />
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 1.5rem;

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
  }
`

const LeftSide = styled.div`
  width: 50%;
  border-left: 0.0625rem solid
    ${({ theme }) => theme.colors.variants.neutralLight5};
  color: ${({ theme }) => theme.colors.variants.neutralLight5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  line-height: 1.875rem;
  padding-left: 1.25rem;

  @media (max-width: 1199px) {
    width: 100%;
    order: 2;
    padding-left: 0.9375rem;
  }
`

const Slider = styled.div`
  display: flex;
  outline: none;
  overflow: hidden;
`

const Slide = styled.div`
  overflow: hidden;
  position: relative;
`

const Description = styled.div`
  font-size: 1.5rem;
  font-style: italic;
`

const User = styled.div`
  font-size: 1.125rem;
  margin-top: 2.5rem;
`

const Arrows = styled(FlexBox)`
  margin-top: 3.75rem;

  > div {
    &:hover {
      background: ${({ theme }) => theme.colors.variants.neutralLight5};

      svg {
        stroke: ${({ theme }) => theme.colors.variants.neutralDark3};
      }
    }
    &:last-of-type {
      margin-left: 0.75rem;
    }
  }

  @media (max-width: 1199px) {
    margin-top: 30px;
  }
`

const RightSide = styled.div`
  width: 50%;
  text-align: right;

  svg {
    width: auto;
    height: 7.3125rem;
    fill: ${({ theme }) => theme.colors.variants.dangerDark};
    transform: translateY(-0.625rem);
  }

  @media (max-width: 1199px) {
    width: 100%;
    text-align: left;

    svg {
      height: 6.25rem;
      transform: translateY(-0.625rem) scaleX(1);
    }
  }
`
